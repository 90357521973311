@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap);
:root {
  --vh: 1vh;
}

.MuiList-padding.MuiMenu-list {
  background: #253656;
}

a {
  color: #5a81ff;
  text-decoration: none;
}

.three-line-legend {
  width: 100%;
  height: 70px;
  position: absolute;
  padding: 8px;
  font-size: 12px;
  color: #20262e;
  background-color: rgba(255, 255, 255, 0.23);
  text-align: left;
  z-index: 10;
  pointer-events: none;
}

.three-line-legend-dark {
  width: 100%;
  height: 70px;
  position: absolute;
  padding: 8px;
  font-size: 12px;
  color: white;
  background-color: rgba(255, 255, 255, 0.23);
  text-align: left;
  z-index: 10;
  pointer-events: none;
}

@media screen and (max-width: 800px) {
  .three-line-legend {
    display: none !important;
  }
}

.tv-lightweight-charts {
  width: 100% !important;

  & > * {
    width: 100% !important;
  }
}

